<template>
  <nav v-if="numberOfPages > 1" class="pagination-navbar" v-class-mod:pagination-navbar="`size-${size}`">
    <span
      :tabindex="prevDisabled ? -1 : 0"
      class="pagination-navbar__prev"
      @click="() => goTo(page - 1)"
      @keydown.enter="() => goTo(page - 1)"
      v-class-mod:pagination-navbar__prev="{ isDisabled: prevDisabled }"
    />

    <span
      v-for="pageNum in visiblePages || []"
      :tabindex="0"
      class="pagination-navbar__nr"
      @click="() => goTo(pageNum)"
      @keydown.enter="() => goTo(pageNum)"
      :key="pageNum ?? '...'"
      v-class-mod:pagination-navbar__nr="{ isActive: pageNum === page }"
    >
      {{ isNumber(pageNum) ? pageNum : "..." }}
    </span>

    <span
      :tabindex="nextDisabled ? -1 : 0"
      class="pagination-navbar__next"
      @click="() => goTo(page + 1)"
      @keydown.enter="() => goTo(page + 1)"
      v-class-mod:pagination-navbar__next="{ isDisabled: nextDisabled }"
    />

    <modal-wrapper v-if="showDialog" :fixed-overlay="true">
      <modal-box
        :title="translations.pagination.dialogTitle"
        :warning="
          !inputIsActive && inputPage !== page && !isNumberBetween(inputPage, 0, numberOfPages)
            ? interpolate(translations.pagination.warning, { n: numberOfPages })
            : ''
        "
        @close="() => (showDialog = false)"
        @click-outside="() => (showDialog = false)"
      >
        <input-number
          :value="inputPage"
          :auto-select="true"
          :set-focus="true"
          :legend="translations.pagination.page"
          @enter="() => goTo(inputPage)"
          @is-active="(is) => (inputIsActive = is)"
          @update="
            (p) => {
              if (isNumber(p)) {
                inputPage = p;
              }
            }
          "
        />
        <button-groups
          :options="[
            'spacing',
            {
              title: translations.pagination.submit,
              disabled: inputPage === page || !isNumberBetween(inputPage, 0, numberOfPages),
              action: () => goTo(inputPage),
              theme: 'green',
            },
          ]"
        />
      </modal-box>
    </modal-wrapper>
  </nav>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { ButtonSize } from "@horizon56/styles/types";
import { interpolate } from "@horizon56/translations";
import { isNumber, isNumberBetween } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import ButtonGroups from "@/components/buttons/button-groups.vue";
import InputNumber from "@/components/inputs/input-number.vue";
import ModalBox from "@/components/modal/modal-box.vue";
import ModalWrapper from "@/components/modal/modal-wrapper.vue";

type MorePages = null;

const props = withDefaults(
  defineProps<{
    page: number;
    itemsPerPage: number;
    totalItems: number;
    size?: ButtonSize;
  }>(),
  {
    size: "medium",
  },
);

const emit = defineEmits<{
  (e: "update", page: number): void;
}>();

const maxNumberOfButtons = 6;
const showDialog = ref(false);
const inputPage = ref(props.page);
const inputIsActive = ref(false);
const numberOfPages = computed(() => Math.ceil(props.totalItems / props.itemsPerPage));

const prevDisabled = computed(() => props.page === 1);
const nextDisabled = computed(() => props.page === numberOfPages.value);

const visiblePages = computed(() => {
  const pages: Array<number | MorePages> = [];
  if (numberOfPages.value <= maxNumberOfButtons) {
    for (let page = 1; page <= numberOfPages.value; page++) {
      pages.push(page);
    }
  } else {
    pages.push(1);

    if (props.page > 3) {
      pages.push(null);
    }

    if (props.page === numberOfPages.value) {
      pages.push(props.page - 2);
    }

    if (props.page > 2) {
      pages.push(props.page - 1);
    }

    if (props.page !== 1 && props.page !== numberOfPages.value) {
      pages.push(props.page);
    }

    if (props.page < numberOfPages.value - 1) {
      pages.push(props.page + 1);
    }

    if (props.page === 1) {
      pages.push(props.page + 2);
    }

    if (props.page < numberOfPages.value - 2) {
      pages.push(null);
    }
    pages.push(numberOfPages.value);
  }

  return pages;
});

const goTo = (nr: number | MorePages) => {
  if (nr === null) {
    inputPage.value = props.page;
    showDialog.value = true;
    return;
  }

  showDialog.value = false;

  if (isNumberBetween(nr, 0, numberOfPages.value)) {
    emit("update", nr);
  }
};
</script>

<style scoped lang="scss">
.pagination-navbar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  &__nr,
  &__prev,
  &__next {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--black-90);
    height: var(--button-size);
    width: var(--button-size);
    border-radius: 50%;
    --icon-color: var(--black-50);
    &:not(&--isDisabled):not(&--isActive):hover {
      background: var(--black-10);
      cursor: pointer;
      --icon-color: var(--black-90);
    }
    &--isActive {
      background: var(--black-20);
    }
  }
  &__prev,
  &__next {
    font-size: var(--icon-size);
    color: var(--icon-color);
  }
  &__prev {
    margin-right: var(--app-spacing-size-xsmall);
    @include icon-after($icon-chevron_left);
  }
  &__next {
    margin-left: var(--app-spacing-size-xsmall);
    @include icon-after($icon-chevron_right);
  }
  @each $size in $buttonSizes {
    &--size-#{$size} {
      --button-size: var(--app-button-height-#{$size});
      --icon-size: var(--app-icon-size-#{$size});
    }
  }
}
</style>
